<template>
  <v-app id="agents" class="overflow-hidden mt-n1 mt-md-n1 px-md-4">
    <v-container class="pt-10">
      <v-row>
        <v-col cols="12" md="9">
          <v-row>
            <v-col>
              <p class="text-header">Agents & Developers</p>
              <p>
                Enhance your real estate property search in Nigeria by using our online
                property listing platform to connect with our best real estate Developers,
                Agencies, and agents.
              </p>
              <p>
                Collaborate with the best agents to get all the good property finds you’ll
                need.
              </p>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <h3 class="subtitle font-weight-medium mt-9 mb-6">Real Estate Developers/Agents</h3>
              <div class="w-100">
                <p class="font-weight-bolder">Total agents: {{ filteredAgents.length }}</p>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    v-model="search"
                    placeholder="Search by Agent name, Location, etc"
                  />
                  <div class="agent-list">
                    <p v-if="loading">Loading...</p>
                    <p v-else-if="error">{{ error }}</p>
                    <p v-else-if="filteredAgents.length === 0">No agents found.</p>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>

          <v-row v-if="filteredAgents.length">
            <v-col v-for="agent in filteredAgents" :key="agent.id || agent.name" cols="12" sm="6" md="4">
              <v-card class="agent-card">
                <v-img :src="agent.image" height="200px" cover />
                <v-card-title><span class="text-uppercase me-3 font-weight-bolder">{{ agent.last_name }}, </span>{{ agent.first_name }} </v-card-title>
                <v-card-subtitle>
                  <p class="description">{{ agent.description }}</p>
                </v-card-subtitle>
                <v-card-actions>
                  <v-chip color="primary">12 Listings</v-chip>
                  <v-spacer />
                  <v-btn icon><i class="fa fa-google-mail" /></v-btn>
                  <router-link to="#" class="btn btn-floating m-1" style="background-color: #dd4b39" href="#!" role="button"><i class="fab fa-google"></i></router-link>
                  <v-btn icon><i class="fas fa-phone" /></v-btn>
                  <v-btn icon><i class="fab fa-instagram" /></v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
          <p v-else>No agents found.</p>
        </v-col>

        <!-- Filter Sidebar -->
        <v-col cols="12" md="3" class="hidden-sm-and-down">
          <div class="dropdown mb-5">
            <button class="btn btn-white form-control border border-primary d-flex align-items-center" type="button">
              Real Estate Agents
              <span class="ms-5" style="padding-left:80px"><i class="fas fa-chevron-down" /></span>
            </button>
            <ul class="dropdown-menu">
              <li><a class="dropdown-item" href="#">Option 1</a></li>
              <li><a class="dropdown-item" href="#">Option 2</a></li>
              <li><a class="dropdown-item" href="#">Option 3</a></li>
            </ul>
          </div>
          <v-card class="pa-4">
            <h3 class="text-h6 font-weight-bold text-title">Filter</h3>
            <v-divider />
            <v-select label="Sales" variant="outlined" />
            <v-select label="Price Range" variant="outlined" />
            <v-select label="State" variant="outlined" />
            <v-select label="City" variant="outlined" />
            <v-select label="Area" variant="outlined" />
            <v-select label="Category" variant="outlined" />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>


<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      search: "",
      loading: false,
      error: "",
      agents: [],
    };
  },

  computed: {
    ...mapGetters(["processedAgents"]),

    filteredAgents() {
      if (!this.search) return this.agents;
      const query = this.search.toLowerCase();
      return this.agents.filter(agent =>
        agent.name.toLowerCase().includes(query) ||
        agent.description.toLowerCase().includes(query)
      );
    }
  },

  methods: {
    ...mapActions(["getAgent"]),

    async fetchAgents() {
      this.loading = true;
      try {
        await this.getAgent();
        this.agents = this.processedAgents;
      } catch (err) {
        this.error = "Failed to load agents. Please try again.";
        console.error("Fetch error:", err);
      } finally {
        this.loading = false;
      }
    }
  },

  created() {
    this.fetchAgents();
  }
};
</script>


<style scoped>
#agents {
  color: #4a425f;
  background-color: #f7f7f7;
}

@media (min-width: 960px) {
  #left-col {
    width: 75%;
  }
}

@media (min-width: 960px) {
  #right-col {
    width: 25%;
  }
}

.subtitle{
  font-size: 18px;
}

.dropdown {
  margin-bottom: 30px;
}

.text-header{
  font-size: 25px;
  font-weight: bolder;
}

.form-group {
  position: relative;
  width: 100%;
}
.form-control {
  width: 100%;
  padding: 10px;
  padding-left: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #aaa;
}
.agent-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}
.description {
  font-size: 14px;
  color: #555;
}
</style>

