<template>
  <div class="container py-5 mb-5">
    <!-- Header Section -->
    <div class="row text-center">
      <div class="d-flex col-md-12 justify-content-center align-items-center mb-4">
        <div style="margin-top: 55px;" class="text-center">
          <span class="fw-bold new-header">NEW PROJECTS</span><br>
          <span class="underline"></span>
          <p class="lead new-header-sub">Here some of our on-going projects</p>
        </div>
      </div>
    </div>

    <!-- Bootstrap Carousel -->
    <div id="projectCarousel" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div
          v-for="(group, index) in groupedProducts"
          :key="index"
          class="carousel-item"
          :class="{ active: index === 0 }"
        >
          <div class="row">
            <div v-for="product in group" :key="product.id" class="col-12 col-md-4">
              <div class="card property-card border-0 shadow-sm" style="width: 100%; height:50%;">
                <div class="position-relative">
                  <img
                    :src="product.image"
                    class="card-img-top rounded-top"
                    alt="Product Image"
                    v-if="product.image"
                    style="height:320px;"
                    
                  />
                </div>
                <div class="card-body">
                  <p class="new-header">Completed</p>
                  <p class="fw-semibold">{{ product.name }}</p>
                  <p class="text-muted small">
                    <i class="fas fa-map-marker-alt me-1"></i>
                    {{ product.location }}
                  </p>
                  <h5 class="fw-bold">{{ formatCurrency(product.price.toLocaleString()) }}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Left & Right Navigation Buttons -->
      <button class="carousel-control-prev custom-nav" type="button" data-bs-target="#projectCarousel" data-bs-slide="prev">
        <i class="fas fa-chevron-left"></i>
      </button>
      <button class="carousel-control-next custom-nav" type="button" data-bs-target="#projectCarousel" data-bs-slide="next">
        <i class="fas fa-chevron-right"></i>
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      products: [],
      screenWidth: window.innerWidth, // Track screen width
    };
  },
  computed: {
    groupedProducts() {
      const chunkSize = this.screenWidth < 798 ? 1 : 3; // Dynamically update chunk size
      return this.products.reduce((result, item, index) => {
        const chunkIndex = Math.floor(index / chunkSize);
        if (!result[chunkIndex]) {
          result[chunkIndex] = [];
        }
        result[chunkIndex].push(item);
        return result;
      }, []);
    },
  },
  mounted() {
    this.fetchProducts();
    window.addEventListener("resize", this.updateScreenWidth);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.updateScreenWidth);
  },
  methods: {
    formatCurrency(value) {
      if (!value) return "₦0";
      return new Intl.NumberFormat("en-NG", {
        style: "currency",
        currency: "NGN",
        minimumFractionDigits: 0,
      }).format(value);
    },

    async fetchProducts() {
      try {
        const response = await axios.get("http://127.0.0.1:8000/api/featured-product");
        this.products = response.data.data.map((item) => ({
          id: item.id,
          name: item.title,
          price: item.new_price,
          image: item.product_images.length > 0 ? item.product_images[0].full : null,
          location: `${item.lga?.name || "Unknown"}, ${item.state?.name || "Unknown"}`,
        }));
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    },

    updateScreenWidth() {
      this.screenWidth = window.innerWidth;
    },
  },
};
</script>


<style scoped>
.underline {
  display: inline-block;
  width: 100px;
  height: 3px;
  background-color: orange;
  margin-left: 10px;
}
.new-header {
  font-family: Inter;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #1338BD;
  margin-top: 10px;
}
.new-header-sub {
  font-family: Inter;
  font-weight: 600;
  font-size: 35px;
  text-align: center;
  color: #1E1C23;
}

/* Custom Navigation Buttons */
.custom-nav {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 50px;
  height: 50px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 50%;
  border: none;
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background 0.3s ease;
}
.custom-nav:hover {
  background-color: rgba(0, 0, 0, 0.9);
}
.carousel-control-prev {
  left: -50px;
}
.carousel-control-next {
  right: -50px;
}

/* Property Card Styles */
.property-card{
  width: 100%;
  height: 100%;
}

</style>
