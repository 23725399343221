<template>
  <div class="container py-5 mt-5">
    <!-- Header Section -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <div style="margin-top: 54px;">
        <h2 class="fw-bold new-head">Find Future Space at a <br> Suitable Budget</h2>
      </div>

      <!-- View All & Navigation Buttons -->
      <div class="d-flex align-items-center">
        <button class="btn btn-outline-primary rounded-pill">Explore More <i class="fa fa-arrow-right"></i></button><br>
      </div>
    </div>

    <!-- Property Type Buttons and Pagination -->
    <div class="d-flex justify-content-between align-items-center mb-4">
      <!-- Property Types -->
      <div class="d-flex gap-3">
        <button
          v-for="type in ['All', 'Ten Storeys', 'Office Space', 'Bungalow', 'Lands']"
          :key="type"
          class="btn property-type-btn"
          :class="{ 'btn-primary': selectedType === type, 'btn-outline-secondary': selectedType !== type }"
          @click="fetchFeaturedProducts(type)"
        >
          {{ type }}
        </button>
      </div>

      <!-- Pagination -->
      <div class="d-flex align-items-center">
        <button class="pagination-btn me-2 car carousel-btn left me-2" @click="scrollLeft"><i class="fas fa-chevron-left"></i></button>
        <button class="pagination-btn carousel-btn right" @click="scrollRight"><i class="fas fa-chevron-right"></i></button>
      </div>
    </div>

    <!-- Property Cards Section -->
    <div class="position-relative">
      <div class="row flex-nowrap overflow-auto">
        <div class="col-md-3 col-sm-6" v-for="product in products" :key="product.id">
          <div class="card property-card border-0 shadow-sm">
            <div class="position-relative">
              <img
                :src="product.image"
                class="card-img-top rounded-top"
                alt="Product Image"
                v-if="product.image"
                style="height:310px;"
              />
              <div v-if="products.length === 0" class="text-center mt-4">
                  <p>No products available in this category.</p>
                </div>

              <span class="badge product-type-badge">{{ product.product_type || 'N/A' }}</span>
              <span class="badge category-badge">{{ product.category || 'N/A' }}</span>
            </div>
            <div class="card-body">
              <div class="d-flex justify-content-between align-items-center">
                <h5 class="fw-bold mb-0">{{ formatCurrency(product.price.toLocaleString()) }}</h5>
                <button class="btn text-secondary btn-outline-primary">
                  <i class="fas fa-heart"></i>
                </button>
              </div>
              <p class="fw-semibold">{{ product.name }}</p>
              <p class="text-muted small">
                <i class="fas fa-map-marker-alt me-1"></i>{{ product.location }}
              </p>
              <div class="d-flex justify-content-between align-items-center">
                <small class="text-muted"><i class="fas fa-eye me-1"></i> {{ product.view_count }}</small>
                <span class="agent-badge">Agent</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      products: [],
      selectedType: 'All',
    };
  },
  mounted() {
    this.fetchFeaturedProducts(this.selectedType);
  },
  methods: {
    formatCurrency(value) {
      return value ? `₦${value}` : '₦0';
    },
    async fetchFeaturedProducts(category) {
  this.selectedType = category;

  try {
    let endpoint = 'http://127.0.0.1:8000/api/featured-product';

    if (category !== 'All') {
      // Convert category to lowercase and remove spaces to match API
      const formattedCategory = category.toLowerCase().replace(/\s/g, '');
      endpoint += `?category=${formattedCategory}`;
    }

    const response = await axios.get(endpoint);
    this.products = response.data.data.map(item => ({
      id: item.id,
      name: item.title,
      price: item.new_price,
      image: item.product_images.length > 0 ? item.product_images[0].full : null,
      location: `${item.lga?.name || 'Unknown'}, ${item.state?.name || 'Unknown'}`,
      category: item.category_id,
      product_type: item.product_type,
      view_count: item.view_count || 0,
    }));

    if (this.products.length === 0) {
      console.warn('No products available in this category.');
    }
  } catch (error) {
    console.error('Error fetching products:', error);
  }
},
    scrollLeft() {
      document.querySelector('.row.flex-nowrap').scrollBy({ left: -300, behavior: 'smooth' });
    },
    scrollRight() {
      document.querySelector('.row.flex-nowrap').scrollBy({ left: 300, behavior: 'smooth' });
    },
  },
};
</script>

<style scoped>
.property-type-btn {
  border-radius: 20px;
  padding: 5px 15px;
}

.pagination-btn:hover {
  background-color: #6c757d;
  color: white;
}

.carousel-btn {
    border: 1px solid #007bff;
    background: none;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    font-size: 18px;
    color: #007bff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  property-card{
    width:100%;
    height: 100%;
  }
</style>
